import { createStore } from 'vuex';

// import db from '@/db';

// Importing Ingredient Jason Files and Create Map from it
const ingredientContext = require.context('@/assets/data/ingredients', true, /\w+\.json/i);
// // // console.log(ingredientContext.keys(0));
const ingredientMap = new Map();

ingredientContext.keys().forEach((key) => {
  const module = ingredientContext(key);
  // // // console.log(module.ingredient);
  ingredientMap.set(module.ingredient.toLowerCase(), module);
});
// // // console.log(ingredientMap);

// Importing Texture Jason Files and Create Global Map from it
const textureContext = require.context('@/assets/data/textures', true, /\w+\.json/i);
const textureMap = new Map();

textureContext.keys().forEach((key) => {
  const module = textureContext(key);
  textureMap.set(key.replace(/^(?:\W+|\w+\/)+(\w+).json$/, '$1'), module);
});

export default createStore({
  state: {
    ingredients: ingredientMap,
    textures: textureMap,
    highestIndex: 0,
    windowWidth: 0,
    toasties: [],
    installPrompt: null,
    installAsked: false,
  },
  mutations: {
    increaseHighestIndex(state) {
      state.highestIndex += 1;
    },
    setInstallAsked(state, value) {
      state.installAsked = value;
    },
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
    addToasty(state, toasty) {
      // console.log('added');
      // if there is no id yet create new id for toasty
      if (!toasty.id) toasty.id = Math.random().toString(36).substring(2, 9); // eslint-disable-line no-param-reassign
      // add new toasty to toast array
      state.toasties.push(toasty);
      // // // console.log(state.toasties);
    },
    closeToasty(state, id) {
      // find index of toasty by its id
      const i = state.toasties.findIndex((existingToast) => existingToast.id === id);
      // splice toastie
      state.toasties.splice(i, 1);
    },
    setInstallPrompt(state, prompt) {
      state.installPrompt = prompt;
    },
  },
  actions: {
  },
  modules: {
  },
});
