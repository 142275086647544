<template lang="html">
  <div class="dishTile" :class="{square: !imageId}" @click="handleClick">
    <div class="background" :style="{backgroundImage: `url(${imageId})`, backgroundColor: color}"></div>
    <!-- <div class="background" :style="[imageId ? `background-image: url(${imageId})` : `background-color: ${color}`]"></div> -->
    <div class="foreground">
      <p class="head" :class="{shadow: imageId}">{{title}}</p>
      <div class="menuBar">
        <Ui-Icons ref="delete" icon="delete"/>
        <Ui-Icons ref="edit" icon="edit"/>
        <!-- <Ui-Icons ref="export" icon="export"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  emits: [
    'click',
    'delete',
    // 'export',
    'edit',
  ],
  props: {
    imageId: String,
    color: String,
    title: String,
  },
  methods: {
    handleClick(e) {
      if (this.$refs.delete.$el === e.target || this.$refs.delete.$el.contains(e.target)) {
        this.$emit('delete');
      } else if (this.$refs.edit.$el === e.target || this.$refs.edit.$el.contains(e.target)) {
        this.$emit('edit');
      } else {
        this.$emit('click');
      }
      // if ([this.$refs.delete.$el, this.$refs.edit.$el, this.$refs.export.$el].includes(e.target) || this.$refs.delete.$el.contains(e.target) || this.$refs.edit.$el.contains(e.target) || this.$refs.export.$el.contains(e.target)) {
      //   return;
      // }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.dishTile
  width: 29vw
  height: 42.6vw
  box-shadow: 0 0 0 2px $black;
  border-radius: 12px
  position: relative;
  &.square
    height: @width
  &:hover
    cursor: pointer;
    box-shadow: 0 0 0 2px $uiPurple, 5px 5px 10px rgba(47, 35, 45, 0.15);
    .background
      filter: brightness(0.85)

  .background
    border-radius: 12px
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    transition: filter 150ms ease

  .foreground
    padding-top: (5/1920*135)vw
    position: absolute;
    top: 0
    display: flex
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%
    height: 100%
    .head
      color: $white
      position: absolute;
      top: (20/1440*100)vw
      left: (20/1440*100)vw
      width: 80%
      font-family: 'PPPangramSansRounded'
      font-size: (30/1440*100)vw
      line-height: (37/1440*100)vw;
      font-weight: 500
      font-feature-settings: 'ss02' on;
      &.shadow
        text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.32);
    .menuBar
      margin-right: (20/1440*100)vw
      margin-bottom: (20/1440*100)vw
      .icon
        color: $white
        margin-top: (20/16)rem
        &:hover
          color: $uiPurple

@media (max-width:1400px)
  .dishTile
    width: 48vw
    height: 70.5vw
    box-shadow: 0 0 0 2px $black;
    border-radius: 12px
    position: relative;
    &.square
      height: @width
    &:hover
      cursor: pointer;
      box-shadow: 0 0 0 2px $uiPurple, 5px 5px 10px rgba(47, 35, 45, 0.15);
      .background
        filter: brightness(0.85)

    .background
      border-radius: 12px
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      transition: filter 150ms ease

    .foreground
      padding-top: (5/1920*223)vw
      position: absolute;
      top: 0
      display: flex
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%
      height: 100%
      .head
        color: $white
        position: absolute;
        top: (20/1440*165)vw
        left: (20/1440*165)vw
        width: 80%
        font-family: 'PPPangramSansRounded'
        font-size: (30/1440*165)vw
        line-height: (37/1440*165)vw;
        font-weight: 500
        font-feature-settings: 'ss02' on;
        &.shadow
          text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.32);
      .menuBar
        margin-right: (20/1440*165)vw
        margin-bottom: (20/1440*165)vw
        .icon
          color: $white
          margin-top: (20/16)rem
          &:hover
            color: $uiPurple

@media (max-width:750px)
  .dishTile
    width: 92vw
    height: 153vw
    box-shadow: 0 0 0 2px $black;
    border-radius: 8px
    &.square
      height: @width

    .foreground
      padding-top: (5/1920*485)vw
      width: 100%
      height: 100%
      .head
        top: (20/1440*317)vw
        left: (20/1440*317)vw
        width: 80%
        font-size: (30/1440*317)vw
        line-height: (37/1440*317)vw;
      .menuBar
        margin-right: (20/1440*317)vw
        margin-bottom: (20/1440*317)vw
        .icon
          margin-top: (20/16)rem
    .background
      border-radius: 8px
</style>
