/* eslint-disable no-console */

import { register } from 'register-service-worker';
import store from '@/store';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n'
      //   + 'For more details, visit https://goo.gl/AFskqB',
      // );
    },
    registered() {
      // console.log('Service worker has been registered.');
    },
    cached() {
      store.commit('addToasty', {
        labelDecline: 'Alright!',
        iconDecline: 'finish',
        text: 'The Tastery has been cached and is available offline.',
        timeout: 3000,
      });
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated(registration) {
      store.commit('addToasty', {
        action: () => {
          if (registration.waiting) registration.waiting.postMessage({ type: 'SKIP_WAITING' }); // wenn das Update noch wartet, beende das Warten
          window.location.reload(true); // und lade die Seite neu
        },
        labelConfirm: 'Refresh',
        iconConfirm: 'finish',
        text: 'Update is available, refresh to use newest version',
        timeout: 5000,
      });
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
