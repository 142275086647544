<template lang="html">
  <button class="button" :class="mode" @click="$emit('buttonClick', $event)" @focus="$emit('focus', $event)">
    {{label}}
    <Ui-Icons v-if="icon" :icon="icon"/>
  </button>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  emits: [
    'buttonClick',
    'focus',
  ],
  props: {
    icon: String,
    label: String,
    mode: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'

button
  box-sizing: border-box;
  outline: none
  display: flex
  justify-content: space-between;
  align-items: center
  padding: (8/16)rem (13/16)rem  (6/16)rem  (15/16)rem
  height: (48/16)rem
  border: 1px solid $white
  border-radius: $uiRadius
  font-family: 'PPPangramSansRounded'
  font-size: (16/16)rem
  font-weight: 500
  font-feature-settings: 'ss02' on;

  .icon
    margin-left: (10/16)rem
    width: (24/16)rem
    height: @width

  &.cta
    color: $white
    border-color: $uiPurple
    background-color: $uiPurple
    transition: all 100ms ease-out
    &:hover
      border-color: $white
      cursor: pointer
      background-color: $uiPurpleHover
    &:focus
      border-color: $white
      cursor: pointer
  &.dark
    color: $white
    background-color: $deepBlack
    border-color: $white
    transition: all 100ms ease-out
    &:hover
      background-color: $focusBlack
      cursor: pointer
    &:focus
      border-color: $white
      background-color: $focusBlack

  &.light
    color: $black
    background-color: $white
    border-color: $black
    transition: all 100ms ease-out
    &:hover
      background-color: $black
      color: $white
      cursor: pointer
  &.noLabel
    width: (48/16)rem
    height: @width
    padding: 0
    background-color: $deepBlack
    color: $white
    border-radius: 100%
    border: none
    justify-content: center;
    align-items: center
    transition: all 100ms ease-out

    &:hover
      background-color: $focusBlack
      cursor: pointer

    .icon
      margin: 0
</style>
