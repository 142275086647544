export default {
  home: {
    title: 'The Tastery Dashboard',
    help: {
      infoTitle: 'This is your dashboard',
      info: 'Welcome to your Dashboard. This is the place where you manage your dishes. Here you can create new dishes and revisit your collection. With the preview tiles you can share, edit, or delete dishes. If you are looking for a specific dish the search option helps you find it easily.',
    },
    about: {
      infoTitle: 'About the Project',
      info: 'The Tastery was born out of a passion for good food. Searching for a topic for my bachelor thesis, I spent more than a year intensively exploring the art and science of cooking. It was not only that I found out why we salt our food, but also that I discovered the world of aromas, textures and flavor. Out of this fascination, The Tastery was created - a tool and collection of my findings - that enables us to assess our dishes in their taste and to experience those interpretations with playfulness and intuition. <br><br> Coded with Vue 3 by Patrick Jürgens. Communication design generalist, frontend developer and motion designer. Bachelor Thesis at UAS Munich. <br><br>Special thanks to Amadeus Stadler, Alicia Rühr and Xuyen Dam.<br><br> Nosrat, Samin (2019): Salz Fett Säure Hitze, 3. Aufl., München, Deutschland: Verlag Antje Kunstmann. Page/Karen, Dornburg/Andrew (2019): Das Lexikon der Aromen und Geschmackskombinationen, 4. Aufl., Aarau und München, Schweiz: AT Verlag. <br> Coucquyt, Peter/ Lahousse, Bernard/  Langenblick Johan (2020): The Art & Science of Foodpairing, 1. Aufl., London, Großbritannien: Octopus Publishing Group Ltd 2020.<br> Segnit, Niki (2020): Der Geschmackstesaurus, 5. Aufl., München, Deutschland: Piper Verlag.',
    },
  },
  wiki: {
    title: 'The Tastery Wiki',
    infoTitle: 'This is your wiki',
    info: 'Welcome to the Wiki. Here you can find all existing ingredients and textures with their corresponding database entry. As in your dashboard you can use the search option to faster find the element you are curious about.',
  },
  create: {
    titles: {
      desktop: [
        {
          title: 'General informations',
          infoTitle: 'Adding general informations',
          info: 'Let’s create a new dish. First you need to name it. Then you can add as many Ingredients as you like and define its origin. To find your dish later, you can create a preview tile. Simply upload a photo or choose a suitable color.',
        },
        {
          title: 'Taste & Mouthfeel',
          infoTitle: 'Describe taste and mouthfeel',
          info: 'You have already added main information’s about your dish. Now you can describe it in more detail. Create a taste profile to describe the composition of the basic tastes. Select consistencies that describe the mouthfeel.',
        },
        {
          title: 'Key elements',
          infoTitle: 'Prioritise main elements',
          info: 'You added all the elements of your dish. To describe the dish more precisely, you need to prioritize your entries. You can select up to four main ingredients and textures.',
        },
      ],
      mobile: [
        {
          title: 'General information',
          infoTitle: 'Adding general information',
          info: "Let's create a new dish. First you need to name it. Then you can add as many ingredients as you like.<br><br>Next to the photo the title is important to summarize the main information of a dish.A good way to start is to think about the key ingredients and how they are processed. You can also make use of adjectives to emphasize certain aromas or textures.<br><br> Each dish consists of carefully selected ingredients that complement each other. If you are unsure how many ingredients you want to add, eight is good way to start – the average amount of ingredients in western dishes. To add ingredients, enter their name and amount. You can choose the unit from the dropdown menu. Confirm your entries using the enter key or the Add button on the right. The ingredient is added to your list. If you accidentally add something wrong, you can delete the ingredient label by simply clicking on it. For now, you can only choose from ingredients that are part of the application. For more information visit the wiki.",
        },
        {
          title: 'General information',
          infoTitle: 'Adding general information',
          info: 'Now you can define your dishes origin and create a customized preview tile. <br><br>To easily navigate from the dashboard to a dish you can create a preview tile. Customize it by adding a photo and/or a color. <br><br>To define your dishes origin, you can add one or more nationalities. To add a nationality, use the input field and confirm your entries using the Add Nationality button on the right. A list of the nationalities you have added appears beneath it.',
        },
        {
          title: 'Taste',
          infoTitle: 'Describe the taste profile',
          info: 'We perceive taste in the combination of the five basic tastes on our tongue. They are sweet, sour, salty, umami and fatty. Each dish consists of a unique combination of these five tastes that form its flavor. <br><br> To describe the taste profile of your dish, you can adjust the intensity of each individual taste. If you think a certain taste is not included in your dish you may just not add any intensity to it.',
        },
        {
          title: 'Mouthfeel',
          infoTitle: 'Describe the mouthfeel',
          info: 'Besides the taste of a dish, we perceive the sensation in our mouth while we eat. We perceive individual textures but also contrasts. Hence, the interaction of textures is just as important as good taste. You can define your dishes mouthfeel by selecting consistencies from the list.',
        },
        {
          title: 'Key ingredients',
          infoTitle: 'Choose main ingredients',
          info: 'Key ingredients build the core of your dish, they describe its main taste and aroma. If you are unsure which ingredients to prioritize the title is usually a good clue. You can highlight up to four of your previously added ingredients as key ingredients.',
        },
        {
          title: 'Key textures',
          infoTitle: 'Choose main textures',
          info: 'Key textures build the core of your dish’s mouthfeel and tell which consistencies you perceive first. You can highlight up to four of your previously added textures as key textures.',
        },
      ],
    },
  },
  trigger: {
    harmony: 'In some cases, a dish becomes particularly <strong>harmonic</strong> when all existing tastes are similarly intense. Harmonic dishes have no shift of taste in a certain direction.',
    sweet: 'A very simple dish whose <strong>sweet</strong> characteristic overpowers the other tastes.',
    sour: 'A very simple dish whose <strong>sour</strong> characteristic overpowers the other tastes.',
    salty: 'A very simple dish whose <strong>salty</strong> characteristic overpowers the other tastes.',
    umami: 'A very simple dish whose <strong>umami</strong> characteristic overpowers the other tastes.',
    fatty: 'A very simple dish whose <strong>fatty</strong> characteristic overpowers the other tastes.',
    airyTender: 'The contrast between <strong>airy</strong> and <strong>tender</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
    crispTender: 'The contrast between <strong>crisp</strong> and <strong>Tender</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
    coldWarm: 'The contrast between <strong>cold</strong> and <strong>warm</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
    creamyCrunchy: 'The contrast between <strong>creaminess</strong> and <strong>crunchiness</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
    crumblyLiquid: 'The contrast between <strong>crumbly</strong> and <strong>liquid</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
  },
};
