<template>
  <div class="app">
    <router-view class="activeRoute" v-slot="{ Component }">
      <transition mode="out-in">
        <component :is="Component" :key="$route.path"/>
      </transition>
    </router-view>
    <ui-toasty
      v-for="toasty in $store.state.toasties"
      :action="toasty.action"
      :key="toasty.id"
      :text="toasty.text"
      :toastId="toasty.id"
      :timeout="toasty.timeout"
      :labelConfirm="toasty.labelConfirm"
      :labelDecline="toasty.labelDecline"
      :iconConfirm="toasty.iconConfirm"
      :iconDecline="toasty.iconDecline"
      @decline="$store.commit('closeToasty', toasty.id)"
    />
  </div>
</template>

<script>
import UiToasty from './components/UiToasty.vue';
// import db from '@/db';

export default {
  components: {
    UiToasty,
  },
  async created() {
    // Lade die vorinstallierten Dishes in die db:
    // await db.dishes.add(this.testDish);
    window.addEventListener('beforeinstallprompt', (prompt) => {
      prompt.preventDefault();
      this.$store.commit('setInstallPrompt', prompt);
    });
  },
  data() {
    return {
      // testDish,
    };
  },
  watch: {
    async $route(to, from) {
      if (to.name === 'Home' && from.name === 'Tastestory' && this.$store.state.installPrompt && window.localStorage.getItem('userPrompted') !== 'true') {
        window.localStorage.setItem('userPrompted', 'true');
        this.$store.commit('addToasty', {
          action: async () => {
            // // // console.log('Test toasties');
            // So wird der Installationsdialog aufgerufen
            // // console.log('hallo');
            this.$store.state.installPrompt.prompt();
            // Die Antwort des Nutzers landet in userChoice, deshalb warten wir darauf
            const choice = await this.$store.state.installPrompt.userChoice;

            if (choice === 'accepted') {
              this.$store.commit('addToasty', {
                action: () => {
                },
                text: 'Nice! The Tastery should have been added to your homescreen or desktop. You can now launch it from there and close this tab.',
                timeout: 3000,
              });
            } else {
              this.$store.commit('addToasty', {
                action: () => {
                },
                text: 'That\'s okay. We\'re sorry you don\'t like the app.',
                timeout: 3000,
              });
            }
          },
          labelConfirm: 'Install.',
          iconConfirm: 'finish',
          labelDecline: 'No thanks.',
          iconDecline: 'wrong',
          text: 'Would you like to install The Tastery to your device?',
        });
        // await this.$store.dispatch('setInstallAsked', true);
      }
    },
  },
};
</script>

<style lang="stylus">
@require './assets/styles/styles.styl'

#app
  font-family 'PPPangramSansRounded'
  text-align left
  // color #2c3e50
  // margin-top 60px
  // margin: 120px
  .activeRoute
    &.v-enter-active, &.v-leave-active
      transition: all 200ms ease-out;

    &.v-enter-from
      opacity: 0

    &.v-leave-to
      opacity: 0
</style>
