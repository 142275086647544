<template lang="html">
    <div class="inputWrapper" :class="{focussed:focussed, error:error}" @click="focusInput">
        <Ui-Icons v-if="icon" :icon="icon"/>
        <input
        class="inputField"
        type="text"
        :placeholder="defaultText"
        v-model="input"
        @focus="focussed = true"
        @blur="[focussed = false, $emit('changeFocus')]"
        @input="handleInput"
        @keydown.enter="onEnter"
        ref="inputField"
        >
        <Ui-icons class="addContent" :class="{active: input}" v-if="addContentButton" icon="add" @click="handleInput"/>
  </div>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';

export default {
  components: {
    UiIcons,
  },
  mounted() {
    if (this.inputText) this.input = this.inputText;
  },
  props: {
    defaultText: String,
    icon: String,
    addContentButton: Boolean,
    error: Boolean,
    inputText: String,
  },
  data() {
    return {
      focussed: false,
      input: '',
    };
  },
  methods: {
    onEnter() {
      this.$emit('inputEnter', this.input);
    },
    handleInput() {
      this.$emit('inputEnter', this.input);
    },
    focusInput() {
      this.$refs.inputField.focus();
      this.focussed = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.inputWrapper
  width: 100%
  display: flex
  align-items: center
  justify-content: space-space-between;
  border: (1/16)rem solid $white
  border-radius: (5/16)rem
  padding: (7/16)rem (10/16)rem  (7/16)rem  (10/16)rem
  transition: background-color 250ms ease-out
  color: $white
  margin-right: $buttonSpacingDesktop
  height: (48/16)rem

  &.error
    border-color: $uiError
    .inputField
      color: $uiError

  &.focussed
    background-color: $focusBlack
  &:hover
    background-color: $focusBlack
    cursor: pointer

  .icon
    flex-shrink: 0

  .addContent
    margin-right: 0
    color: $gray

    &.active
      color: $white

      &:hover
        color: $uiPurple
        cursor: pointer

  .inputField
    -webkit-user-select:text;
    display: inline-block;
    color: $white
    font-family: 'PPPangramSansRounded'
    font-size: (16/16)rem
    font-weight: 500
    font-feature-settings: 'ss02' on;
    background-color: inherit;
    border: none
    width: 100%
    margin-left: (8/1920*100) vw
    &::placeholder
      color: $gray
    &:focus
      outline: none
</style>
