export default {
  title: 'Orzo with prawns, tomatoes and marinated feta',
  preview: {
    color: '#CEDEED',
    imageId: '',
  },
  data: {
    intensity: 'average',
    season: 'autumn,summer',
    weight: 'low',
    complexity: '',
    nationality: [
      'greece',
    ],
  },
  tasteIntensities: {
    umami: 2,
    salty: 6,
    sweet: 1,
    sour: 2,
    fatty: 1,
  },
  taste: {
    text: 'A very <span class="trigger">→ salty</span> dish with a slight tendency towards umami and sour. The aroma is determined by the main ingredients basil, prawn, tomato and feta.',
    ingredients: [
      {
        title: 'feta',
        ammount: '200 g',
        main: true,
      },
      {
        title: 'tomato',
        ammount: '400 g',
        main: true,
      },
      {
        title: 'prawn',
        ammount: '400 g',
        main: true,
      },
      {
        title: 'basil',
        ammount: '30 g',
        main: true,
      },
      {
        title: 'broth',
        ammount: '500 ml',
        main: false,
      },
      {
        title: 'lemon zest',
        ammount: '3 pc.',
        main: false,
      },
      {
        title: 'garlic',
        ammount: '3 pc.',
        main: false,
      },
      {
        title: 'rice',
        ammount: '250 g',
        main: false,
      },
      {
        title: 'olive oil',
        ammount: '75 ml',
        main: false,
      },
      {
        title: 'fennel seeds',
        ammount: '4 tsp.',
        main: false,
      },
      {
        title: 'chili',
        ammount: '1 tsp.',
        main: false,
      },
    ],
    tasteProfile: [
      {
        taste: 'umami',
        main: false,
        color: '#733F4B',
        colorShade: '#4D2C33',
      },
      {
        taste: 'salty',
        main: true,
        color: '#CEDEED',
        colorShade: '#8D9AA6',
      },
      {
        taste: 'sweet',
        main: false,
        color: '#FF546D',
        colorShade: '#A63747',
      },
      {
        taste: 'sour',
        main: false,
        color: '#DEF25C',
        colorShade: '#96A343',
      },
      {
        taste: 'fatty',
        main: false,
        color: '#E5DFB8',
        colorShade: '#ADA88C',
      },
    ],
    trigger: [
      {
        title: 'salty',
        descrption: 'A very simple dish whose <strong>salty</strong> characteristic overpowers the other tastes.',
      },
    ],
  },
  aroma: {
    text: 'The aroma of the dish is determined by its main ingredients and tastes especially green, floral, citrus, brine and fruity with subtle spicy nuances.',
    aromaProfile: {
      brine: 7,
      cheesy: 3,
      citrus: 9,
      earthy: 4,
      floral: 10,
      fruity: 9,
      green: 10,
      herbal: 4,
      maritime: 5,
      meaty: 3,
      mustard: 0,
      nutty: 2,
      roasted: 1,
      spicy: 9,
      sulphuric: 5,
      woody: 2,
    },
  },
  texture: {
    text: 'Considering the mouthfeel, you can perceive a <span class="trigger"> → contrast </span> between <strong>tender</strong> and <strong>crisp</strong>. The mouthfeel is above all crisp and crumbly.',
    textures: [
      {
        title: 'creamy',
        main: false,
      },
      {
        title: 'crisp',
        main: true,
      },
      {
        title: 'crumbly',
        main: true,
      },
      {
        title: 'elastic',
        main: false,
      },
      {
        title: 'solid',
        main: false,
      },
      {
        title: 'tender',
        main: false,
      },
      {
        title: 'thick',
        main: false,
      },
      {
        title: 'warm',
        main: false,
      },
    ],
    trigger: [
      {
        title: 'crispTender',
        descrption: 'The contrast between <strong>crisp</strong> and <strong>Tender</strong> is one of the most important fundamental contrasts that create an exciting mouthfeel.',
      },
    ],
  },
  id: -2,
};
