<template lang="html">
  <teleport to="body">
    <transition name="background">
      <div  v-if="visible" class="backgroundModal"></div>
    </transition>
    <transition name="modal">
      <div  v-if="visible" class="modalWrapper"  @click.self="$emit('close')">
        <div class="modal">
          <header>
            <h3 v-if="title">{{title}}</h3>
            <ui-icons icon="close" @click="$emit('close')"/>
          </header>
          <p v-html="text" class="text"></p>
          <Ui-Button v-if="confirm" :label="confirm" mode="cta" icon="finish" @buttonClick="$emit('close')"/>
        </div>
      </div>
    </transition>
</teleport>
</template>

<script>
import UiIcons from '@/components/UiIcons.vue';
import UiButton from '@/components/UiButton.vue';

export default {
  components: {
    UiIcons,
    UiButton,
  },
  emits: [
    'close',
  ],
  props: {
    title: String,
    text: String,
    visible: Boolean,
    confirm: String,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.backgroundModal
  z-index: 2
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  background-color: rgba(18, 10, 19, 0.95);

.modalWrapper
  z-index: 2
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center

  .modal
    max-width: (500/16)rem
    max-height: 90%
    width: 90%
    padding: (13/16)rem (13/16)rem (22/16)rem (17/16)rem;
    background-color: $deepBlack
    border: 2px solid $white
    border-radius: 5px
    drop-shadow: (0px 3px 10px rgba(0, 0, 0, 0.35));
    overflow: scroll;
    // -webkit-mask-image: linear-gradient(180deg, $deepBlack 90%, transparent);
    header
      margin-bottom: (20/16)rem
      display: flex
      justify-content: space-between;
      align-items: center
      color: $white
      h3
        font-family: 'PPPangramSansRounded'
        font-size: (20/16)rem
        color: $white
        font-weight: 600
        line-height: (19/16)rem
        font-feature-settings: 'ss02' on;

      .icon:hover
        color: $uiPurple
        cursor: pointer
    .button
      width: 100%
      margin-top: (25/16)rem
    .text
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      color: $white
      font-weight: 400
      line-height: (22/16)rem
      font-feature-settings: 'ss02' on;

.modal-enter-active,
.modal-leave-active
  transition: opacity 250ms ease-out, transform 250ms ease-out

.modal-enter-from,
.modal-leave-to
  opacity: 0
  transform: translateY(20%)

.modal-enter-to,
.modal-leave-from
  opacity: 1
  transform: translateY(0%)

.background-enter-active,
.background-leave-active
  transition: opacity 250ms ease-out

.background-enter-from,
.background-leave-to
  opacity: 0

.background-enter-to,
.background-leave-from
  opacity: 1

@media (max-width:750px)
  .modalWrapper
    .modal
      width: 94%
</style>
