<template lang="html">
  <svg width="24" height="24" class="icon" fill="none"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g v-if="icon === 'hover'" key="hover">
      <rect x="8" y="4" width="12" height="12" stroke="currentColor"
      stroke-width="2" stroke-linejoin="round"/>
      <circle cx="4" cy="8" r="1" fill="currentColor"/>
      <circle cx="4" cy="12" r="1" fill="currentColor"/>
      <circle cx="4" cy="20" r="1" fill="currentColor"/>
      <circle cx="8" cy="20" r="1" fill="currentColor"/>
      <circle cx="12" cy="20" r="1" fill="currentColor"/>
      <circle cx="16" cy="20" r="1" fill="currentColor"/>
      <circle cx="4" cy="16" r="1" fill="currentColor"/>
    </g>
    <g v-if="icon === 'close'" key="close">
      <path d="M17.4991 17.4996L6.5 6.50049" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <path d="M17.4991 6.49994L6.5 17.499" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </g>
    <g v-if ="icon === 'add'" key="add">
      <path d="M12.0008 16L12 8" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <path d="M16 12L8 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <rect x="4" y="4" width="16" height="16" rx="2" stroke="currentColor" stroke-width="2"/>
    </g>
    <g v-if ="icon === 'wiki'" key="wiki">
      <rect x="7.5" y="3.5" width="13" height="13" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M3.5 8.5V18.5C3.5 19.6046 4.39543 20.5 5.5 20.5H15.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <line x1="11" y1="7" x2="17" y2="7" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <line x1="11" y1="10" x2="17" y2="10" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <line x1="11" y1="13" x2="14" y2="13" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </g>
    <g v-if ="icon === 'finish'" key="finish">
      <path d="M14 7L19 12L14 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 7L13 12L8 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'help'" key="help">
      <path d="M12 16L12 11" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <circle cx="11.9999" cy="7.9999" r="1.1" fill="currentColor"/>
      <rect x="4" y="4" width="16" height="16" rx="2" stroke="currentColor" stroke-width="2"/>
    </g>
    <g v-if ="icon === 'search'" key="search">
      <circle cx="10.0711" cy="10.0713" r="5" transform="rotate(-45 10.0711 10.0713)" stroke="currentColor" stroke-width="2"/>
      <path d="M13.5303 14.3764C13.3691 14.1345 13.4009 13.8124 13.6065 13.6069C13.8121 13.4013 14.1342 13.3694 14.3761 13.5307L15.8528 14.5151C16.0048 14.6165 16.1465 14.7326 16.2757 14.8618L19.9705 18.5566C20.361 18.9471 20.361 19.5803 19.9705 19.9708C19.58 20.3613 18.9468 20.3613 18.5563 19.9708L14.8615 16.276C14.7323 16.1468 14.6161 16.0052 14.5148 15.8531L13.5303 14.3764Z" fill="currentColor"/>
    </g>
    <g v-if ="icon === 'delete'" key="delete">
      <path d="M8 9.5H16V17C16 17.8284 15.3284 18.5 14.5 18.5H9.5C8.67157 18.5 8 17.8284 8 17L8 9.5Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M7 6.5H17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.56066 4.93934C8.84196 4.65804 9.2235 4.5 9.62132 4.5H14.3787C14.7765 4.5 15.158 4.65804 15.4393 4.93934C16.3843 5.88429 15.715 7.5 14.3787 7.5H9.62132C8.28496 7.5 7.61571 5.88429 8.56066 4.93934Z" fill="currentColor"/>
    </g>
    <g v-if ="icon === 'dropdownUp'" key="dropdownUp">
      <path d="M7.86232 14.5C7.40716 14.5 7.18873 13.9413 7.52318 13.6326L11.661 9.81305C11.8526 9.63625 12.1478 9.63625 12.3393 9.81305L16.4771 13.6326C16.8116 13.9413 16.5932 14.5 16.138 14.5H7.86232Z" fill="currentColor"/>
    </g>
    <g v-if ="icon === 'dropdown'" key="dropdown">
      <path d="M7.86232 10C7.40716 10 7.18873 10.5587 7.52318 10.8674L11.661 14.6869C11.8526 14.8637 12.1478 14.8637 12.3393 14.6869L16.4771 10.8674C16.8116 10.5587 16.5932 10 16.138 10H7.86232Z" fill="currentColor"/>
    </g>
    <g v-if ="icon === 'removeEntry'" key="removeEntry">
      <path d="M16.0007 15.0007L10.0005 9.00049" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <path d="M16.0002 8.99978L10 15" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <path d="M5.13687 6.06539C5.48328 5.41001 6.16377 5 6.90506 5H21V19H6.90506C6.16377 19 5.48328 18.59 5.13687 17.9346L2.49401 12.9346C2.18493 12.3499 2.18493 11.6501 2.49401 11.0654L5.13687 6.06539Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'info'" key="info">
      <rect x="4" y="4" width="16" height="16" rx="2" stroke="currentColor" stroke-width="2"/>
      <path d="M11.998 13.8539L11.998 12.519" stroke="currentColor" stroke-width="1.79858" stroke-linecap="round"/>
      <circle cx="11.9619" cy="16.5517" r="0.989219" fill="currentColor"/>
      <path d="M11.9982 12.5052C13.4882 12.5052 14.6587 11.7469 14.6587 10.257C14.6587 8.76696 13.4509 7.55908 11.9609 7.55908C10.6241 7.55908 9.51436 8.53134 9.30029 9.80731" stroke="currentColor" stroke-width="1.79858" stroke-linecap="round"/>

    </g>
    <g v-if ="icon === 'back'" key="back">
      <path d="M11 7L6 12L11 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 12H18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'edit'" key="edit">
      <path d="M4.32996 19.5282L5.14854 16.0492L13.6414 7.55639L16.3018 10.2168L7.80894 18.7096L4.32996 19.5282Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M19.2847 8.56438C18.8942 8.9549 18.2611 8.9549 17.8705 8.56438L15.2941 5.98799C14.9036 5.59746 14.9036 4.9643 15.2941 4.57378L16.1609 3.70706C16.5514 3.31653 17.1846 3.31653 17.5751 3.70706L20.1515 6.28345C20.542 6.67397 20.542 7.30713 20.1515 7.69766L19.2847 8.56438Z" fill="currentColor"/>
    </g>
    <g v-if ="icon === 'export'" key="export">
      <path d="M16 10H17C18.1046 10 19 10.8954 19 12V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V12C5 10.8954 5.89543 10 7 10H8" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      <path d="M16 6L12 2L8 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 2L12 13.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'upload'" key="upload">
      <path d="M17 13L12 8L7 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 8L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 5H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'previous'" key="previous">
      <path d="M11 7L6 12L11 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'next'" key="next">
      <path d="M14 7L19 12L14 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'share'" key="share">
      <path d="M8.49981 9.99996L13.4997 6.49998" stroke="currentColor" stroke-width="2"/>
      <path d="M8.5 13L13.5 16" stroke="currentColor" stroke-width="2"/>
      <circle cx="6.5" cy="11.5" r="2.5" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <circle cx="15.5" cy="5.5" r="2.5" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <circle cx="15.5" cy="17.5" r="2.5" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    </g>
    <g v-if ="icon === 'top'" key="top">
      <path d="M7 14L12 9L17 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'hover',
    },
  },
};
</script>

<style lang="stylus" scoped>

@require '../assets/styles/styles.styl'

.icon
  display: block

</style>
