<template lang="html">
  <transition name="toasty">
    <teleport to="body">
      <div class="toastyWrapper">
        <div class="toasty">
          <header v-if="title">
            <h3>{{title}}</h3>
          </header>
          <p v-html="text" class="text"></p>
          <div class="buttonWrapper">
            <Ui-Button v-if="labelDecline" :label="labelDecline" mode="dark" :icon="iconDecline" @buttonClick="handleClose"/>
            <Ui-Button v-if="labelConfirm" :label="labelConfirm" mode="cta" :icon="iconConfirm" @buttonClick="handleAction"/>
          </div>
        </div>
      </div>
    </teleport>
</transition>
</template>

<script>
import UiButton from '@/components/UiButton.vue';

export default {
  components: {
    UiButton,
  },
  created() {
    if (this.timeout) {
      this.timeoutId = window.setTimeout(() => {
        this.$store.commit('closeToasty', this.toastId);
      }, this.timeout);
    }
  },
  emits: [
    'decline',
    'confirm',
  ],
  props: {
    timeout: Number,
    toastId: String,
    title: String,
    text: String,
    visible: Boolean,
    labelConfirm: String,
    labelDecline: String,
    action: Function,
    iconDecline: String,
    iconConfirm: String,
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  methods: {
    handleClose() {
      window.clearTimeout(this.timeoutId);
      this.$emit('decline');
    },
    handleAction() {
      window.clearTimeout(this.timeoutId);
      this.action();
      this.$store.commit('closeToasty', this.toastId);
    },
  },
};
</script>

<style lang="stylus">
@require '../assets/styles/styles.styl'

.toastyWrapper
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: flex
  align-items: flex-start;
  justify-content: center

  .toasty
    margin-top: (20/16)rem
    max-width: (700/16)rem
    width: 90%
    padding: (13/16)rem (13/16)rem (22/16)rem (17/16)rem;
    background-color: $deepBlack
    border: 1px solid $white
    border-radius: 5px
    drop-shadow: (0px 3px 10px rgba(0, 0, 0, 0.35));
    header
      margin-bottom: (20/16)rem
      display: flex
      justify-content: space-between;
      align-items: center
      color: $white

      .icon:hover
        color: $uiPurple
        cursor: pointer
    .buttonWrapper
      margin-top: (25/16)rem
      display: flex
      justify-content: space-between;
      .button
        width: 48%
    .text
      font-family: 'PPPangramSansRounded'
      font-size: (16/16)rem
      color: $white
      font-weight: 400
      line-height: (19/16)rem
      font-feature-settings: 'ss02' on;

.toasty-enter-active,
.toasty-leave-active
  transition: opacity 250ms ease-out, transform 250ms ease-out

.toasty-enter-from,
.toasty-leave-to
  opacity: 0
  transform: translateY(20%)

.toasty-enter-to,
.toasty-leave-from
  opacity: 1
  transform: translateY(0%)

@media(max-width:750px)
  .toastyWrapper

    .toasty
      margin-top: (20/16)rem
      max-width: (700/16)rem
      width: 94%

</style>
