import Dexie from 'dexie';

// import testDish from '@/assets/data/testDish';

const recipeContext = require.context('@/assets/data/dishes', true, /\w+\.js/i);
// // // console.log(ingredientContext.keys(0));
const dishes = [];

recipeContext.keys().forEach((key) => {
  const module = recipeContext(key);
  // // // console.log(module.ingredient);
  dishes.push(module.default);
});

const db = new Dexie('TasteData');

db.version(1).stores({
  dishes: '++id, title',
});

db.on('populate', () => {
  dishes.forEach((dish) => {
    db.dishes.add(dish);
  });
  // db.dishes.add(testDish);
});
export default db;
