<template>
  <div class="home">
    <div class="headWrapper">
      <h3>{{content.home.title}}</h3>
      <div class="leftWrapper">
        <Ui-Input icon="search" defaultText="Enter Text" @inputEnter="filter=$event" @changeFocus="filter=''"/>
        <Ui-Button label="Create New" @buttonClick="navigateNewDish" mode="cta" icon="add"/>
      </div>
      <div class="rightWrapper">
        <Ui-Button @buttonClick="navigateWiki" :label="buttonType === 'dark' ? 'Ingredient Wiki': ''" :mode="buttonType" icon="wiki"/>
        <Ui-Button @buttonClick="handleModal('help')" :label="buttonType === 'dark' ? 'Get Help': ''" :mode="buttonType" icon="help"/>
        <Ui-Button @buttonClick="handleModal('about')" :label="buttonType === 'dark' ? 'About': ''" :mode="buttonType" icon="info"/>
        <ui-modal @close="visibleModal=false" :visible="visibleModal" :title="modalTitle" confirm="Continue" :text="modalText"/>
      </div>
    </div>
    <div v-if="dishes.length === 0" class="emptyState">
      <h1>Seems like you have not yet created a dish.</h1>
      <Ui-Button label="Create your first dish" @buttonClick="navigateNewDish" mode="dark" icon="add"/>
    </div>
    <transition name ="scrollPage">
      <div class="contentScrollPage" v-if="dishes.length > 0">
        <div class="content">
          <div  class="gridContainer" :style="{height: calcContainerHeight}">
            <ui-dish-tile
            v-for="(dish, index) in filterDishes"
            :key="dish.title"
            :title="dish.title"
            :imageId="dish.preview.imageId"
            :color="dish.preview.color"
            @click="navigateTastestory(index)"
            @delete="deleteDish(index)"
            @edit="editDish(index)"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import db from '@/db';

import UiButton from '@/components/UiButton.vue';
import UiInput from '@/components/UiInput.vue';
import UiDishTile from '@/components/UiDishTile.vue';
import UiModal from '@/components/UiModal.vue';

import content from '@/assets/data/content';
// import testDish from '../assets/data/testDish';

export default {
  name: 'Home',
  components: {
    UiButton,
    UiInput,
    UiDishTile,
    UiModal,
  },
  async created() {
    // Hole die bereits erstellen dishes aus dem Store
    try {
      // Lade die restlichen Dishes aus der Datenbank
      this.dishes = await db.dishes.reverse().toArray();
      // // console.log(this.dishes);
    } catch (err) {
      console.warn(`Error fetching dishes: ${err.message}`);
    }
    // // // console.log(this.$store.state.dishes);
  },
  computed: {
    filterDishes() {
      if (!this.filter.trim()) {
        return this.dishes;
      }
      // // console.log(this.dishes);
      return this.dishes.filter((element) => element.title.toLowerCase().indexOf(this.filter.toLowerCase()) > -1);
    },
    calcContainerHeight() {
      // // console.log(this.dishes.length);
      if (this.dishes.length === 0) return '1500px';
      // const dishes = document.querySelectorAll('.dishTile');
      // // // console.log(dishes);
      if (this.windowWidth > 1400) {
      //   // Finde heraus wie viele Dishes im Array sind und wie viele Zeilen möglich sind
      //   const rows = Math.floor(this.dishes.length / 3);
      //   // // console.log(rows);
      //   // Find das jeweils größte Element in der Reihe und addiere diese
      //   let calcHeight = dishes.item(0).getBoundingClientRect().height;
      //   for (let i = 1; i <= rows; i += 1) {
      //     calcHeight += dishes.item((i * 3) - 1).getBoundingClientRect().height;
        // const currentHeight = Math.floor(this.dishes.length / 3) * 1000;
        // // // console.log(currentHeight);
        return `${Math.ceil(this.dishes.length / 3) * 1200}px`;
      }
      if (this.windowWidth <= 1400) {
        return `${Math.ceil(this.dishes.length / 2) * 1200}px`;
      }
      if (this.windowWidth < 750) {
        return `${this.dishes.length * 1000}px`;
      }
      // console.log('An error occured on Dish Container calculation');
      return '0px';
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 1350) { this.buttonType = 'noLabel'; }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    windowWidth(newValue) {
      if (newValue < 1350) {
        this.buttonType = 'noLabel';
      } else {
        this.buttonType = 'dark';
      }
    },
  },
  methods: {
    editDish(index) {
      // // console.log(this.dishes[index].id);
      this.$router.push({ name: 'CreateNew', params: { index: this.dishes[index].id } });
    },
    deleteDish(index) {
      // // console.log(this.dishes[index].id);
      this.$store.commit('addToasty', {
        action: async () => {
          try {
            await db.dishes.delete(this.dishes[index].id);
            this.dishes.splice(index, 1);
          } catch (err) {
            console.warn(`Error deleting dish: ${err.message}`);
          }
        },
        labelDecline: 'Keep this dish',
        labelConfirm: 'Delete Dish',
        iconConfirm: 'delete',
        iconDecline: 'finish',
        text: `Do you really want to delete "${this.dishes[index].title}"?`,
      });
    },
    handleModal(type) {
      this.visibleModal = true;
      if (type === 'about') {
        this.modalText = this.content.home.about.info;
        this.modalTitle = this.content.home.about.infoTitle;
      }
      if (type === 'help') {
        this.modalText = this.content.home.help.info;
        this.modalTitle = this.content.home.help.infoTitle;
      }
    },
    onResize() {
      // // // console.log(window.innerWidth);
      this.$store.commit('setWindowWidth', window.innerWidth);
      this.windowWidth = window.innerWidth;
      // // // console.log(this.$store.state.windowWidth);
    },
    navigateTastestory(index) {
      // Setze das anzuzeigende Gericht in den Store
      // Hier muss später der Index des Gerichts aus der Liste hin!
      // this.$store.commit('setCurrentDish', index);
      if (this.$route.name === 'Tastestory') return;
      // // console.log(this.dishes[index].id);
      this.$router.push({ name: 'Tastestory', params: { index: this.dishes[index].id } });
    },
    navigateDev() {
      if (this.$route.name === 'Development') return;
      this.$router.push({ name: 'Development' });
    },
    navigateWiki() {
      if (this.$route.name === 'Wiki') return;
      this.$router.push({ name: 'Wiki' });
    },
    navigateNewDish() {
      if (this.$route.name === 'CreateNew') return;
      this.$router.push({ name: 'CreateNew', params: { index: 'new' } });
    },
  },
  data() {
    return {
      filter: '',
      dishesResults: [],
      buttonType: 'dark',
      content,
      // testDish,
      windowWidth: 0,
      visibleModal: false,
      modalText: '',
      modalTitle: '',
      dishes: [],
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/styles.styl'
.home
  background-color: $deepBlack
  .emptyState
    position: absolute;
    top: 45%
    width: 50%
    h1
      color: $white
    .button
      margin-top: 2rem
  .headWrapper
    position: fixed
    top: (40/16) rem
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    width: 90%

    h3
      color: $white
      margin-bottom: (36/16)rem
    .leftWrapper
      grid-row: 2
      display: flex
      .inputWrapper
        max-width: (700/16)rem
        width: 28vw
    .rightWrapper
      grid-column: 2
      grid-row: 1 /span 2
      display: flex
      align-self: end
      justify-self: end
      .button
        margin-left:$buttonSpacingDesktop

  .contentScrollPage
    width:100vw
    background-color: $white
    border-radius: (18/16)rem
    position: absolute;
    left: 0
    top: (188/16)rem
    .content
      padding-top: 3%
      margin: 0 auto
      width: 90%
      .gridContainer
        display: flex
        flex-flow: column wrap
        align-content: space-between
        // height: 3000px
        &:before,
        &:after
          content: '';
          flex-basis: 100%;
          width: 0;
          order: 2;

        .dishTile
          width: 32%
          margin-bottom: 2%
          &:nth-child(3n+1)
            order: 1
          &:nth-child(3n+2)
            order: 2;
          &:nth-child(3n)
            order: 3
.scrollPage-enter-active,
.scrollPage-leave-active
  transition: opacity 350ms ease-out, transform 350ms ease-out

.scrollPage-leave-to
  opacity: 0
  transform: translateY(100%)

.scrollPage-enter-to,
.scrollPage-leave-from
  opacity: 1
  transform: translateY(0%)

@media (max-width:1400px)
  .home
    .headWrapper
      position: fixed
      top: (40/16) rem
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: (50/16)rem
      width: 92%

      h3
        color: $white

    .leftWrapper
      grid-row: 2
      display: flex
      .inputWrapper
        max-width: (700/16)rem
        width: 28vw
      .rightWrapper
        grid-column: 2
        grid-row: 1
        display: flex
        align-self: start
        justify-self: end
        margin-top: (-10/16)rem
        .button
          margin-left:$buttonSpacingDesktop

    .contentScrollPage
      .content
        width: 94%
        padding-top: 3%
        .gridContainer
          margin-right: 1%
          display: flex
          flex-flow: column wrap
          align-content: space-between
          height: 3000px
          &:before,
          &:after
            content: '';
            flex-basis: 100%;
            width: 0;
            order: 1;

          .dishTile
            width: 48%
            margin-bottom: 3%
            &:nth-child(2n+1)
              order: 1
            &:nth-child(2n+2)
              order: 2;
            // &:nth-child(3n)
            //   order: 1

@media (max-width:750px)
  .home
    .emptyState
      position: absolute;
      top: 45%
      width: 90%
      h1
        color: $white
      .button
        margin-top: 2rem
    .headWrapper
      position: fixed
      top: (40/16) rem
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: (50/16)rem
      width: 94%

      h3
        color: $white

      .leftWrapper
        grid-row: 2
        grid-column: 1 / span 2
        display: flex
        flex-wrap: wrap;
        .inputWrapper
          width: 100%
          margin-right: 0
        .button
          width: 100%
          margin-top: (16/16)rem
      .rightWrapper
        grid-column: 2
        grid-row: 1
        display: flex
        align-self: start
        justify-self: end
        margin-top: (-10/16)rem
        .button
          margin-left:$buttonSpacingDesktop

    .contentScrollPage
      top: (255/16)rem
      border-radius: 14px
      .content
        width: 92%
        padding-top: 6%
        .gridContainer
          display: block
          height: auto
          &:before,
          &:after
            content: '';
            flex-basis: 100%;
            width: 0;
            order: 2;

          .dishTile
            width: auto
            margin-bottom: 4%

</style>
