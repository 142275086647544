export default {
  title: 'Bombay Eggs',
  preview: {
    color: '#733F4B',
    imageId: '',
  },
  data: {
    intensity: 'intense',
    season: 'autumn,summer',
    weight: 'low',
    complexity: '',
    nationality: [
      'india',
    ],
  },
  tasteIntensities: {
    sweet: 3,
    sour: 3,
    umami: 4,
    salty: 4,
    fatty: 4,
  },
  taste: {
    text: 'A <span class="trigger">→ harmonious</span> dish with a slight tendency towards umami, salty and fatty. The aroma is determined by the main ingredients egg and tomato.',
    ingredients: [
      {
        title: 'cilantro',
        ammount: '3 tbsp.',
        main: false,
      },
      {
        title: 'joghurt',
        ammount: '250 g',
        main: false,
      },
      {
        title: 'egg',
        ammount: '8 pc.',
        main: true,
      },
      {
        title: 'spinach',
        ammount: '250 g',
        main: false,
      },
      {
        title: 'sugar',
        ammount: '1 tsp.',
        main: false,
      },
      {
        title: 'tomato',
        ammount: '400 g',
        main: true,
      },
      {
        title: 'ginger',
        ammount: '1 tbsp.',
        main: false,
      },
      {
        title: 'garlic',
        ammount: '4 pc.',
        main: false,
      },
      {
        title: 'onion',
        ammount: '2 pc.',
        main: false,
      },
      {
        title: 'olive oil',
        ammount: '4 tbsp.',
        main: false,
      },
      {
        title: 'coriander seeds',
        ammount: '2 tsp.',
        main: false,
      },
      {
        title: 'cumin seeds',
        ammount: '1 tsp.',
        main: false,
      },
    ],
    tasteProfile: [
      {
        taste: 'sweet',
        main: false,
        color: '#FF546D',
        colorShade: '#A63747',
      },
      {
        taste: 'sour',
        main: false,
        color: '#DEF25C',
        colorShade: '#96A343',
      },
      {
        taste: 'umami',
        main: true,
        color: '#733F4B',
        colorShade: '#4D2C33',
      },
      {
        taste: 'salty',
        main: true,
        color: '#CEDEED',
        colorShade: '#8D9AA6',
      },
      {
        taste: 'fatty',
        main: true,
        color: '#E5DFB8',
        colorShade: '#ADA88C',
      },
    ],
    trigger: [
      {
        title: 'harmony',
        descrption: 'In some cases, a dish becomes particularly <strong>harmonic</strong> when all existing tastes are similarly intense. Harmonic dishes have no shift of taste in a certain direction.',
      },
      {
        title: 'harmony',
        descrption: 'In some cases, a dish becomes particularly <strong>harmonic</strong> when all existing tastes are similarly intense. Harmonic dishes have no shift of taste in a certain direction.',
      },
      {
        title: 'harmony',
        descrption: 'In some cases, a dish becomes particularly <strong>harmonic</strong> when all existing tastes are similarly intense. Harmonic dishes have no shift of taste in a certain direction.',
      },
    ],
  },
  aroma: {
    text: 'The aroma of the dish is determined by its main ingredients and tastes especially spicy, green, citrus, earthy and fruity with subtle sulphuric nuances.',
    aromaProfile: {
      brine: 2,
      cheesy: 2,
      citrus: 10,
      earthy: 7,
      floral: 5,
      fruity: 7,
      green: 8,
      herbal: 5,
      maritime: 0,
      meaty: 0,
      mustard: 0,
      nutty: 1,
      roasted: 5,
      spicy: 9,
      sulphuric: 7,
      woody: 3,
    },
  },
  texture: {
    text: ' The mouthfeel is above all creamy and tender.',
    textures: [
      {
        title: 'creamy',
        main: true,
      },
      {
        title: 'greasy',
        main: false,
      },
      {
        title: 'tender',
        main: true,
      },
      {
        title: 'thick',
        main: false,
      },
      {
        title: 'warm',
        main: false,
      },
    ],
    trigger: [],
  },
  id: -5,
};
